<template>
  <div class="mt-4">
    <v-row dense class="my-0" v-for="(procedure, i) of localProceduresToCreate" :key="i">
      <v-col>
        <v-row dense>
          <v-col cols="1">
            <v-select outlined required v-model="procedure.aditivo" item-value="value"
              item-text="nome" :items="statusOptions" :rules="requiredField" @change="handleAditivoChange(i)">
              <template v-slot:label>
                <span>Aditivo<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="procedure.aditivo" cols="1">
            <v-text-field outlined required v-model="procedure.nr_aditivo" type="number" :rules="nrAditivoRules(procedure)">
              <template v-slot:label>
                <span>Nrº do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col v-if="procedure.aditivo" cols="3">
            <v-text-field outlined required v-model="procedure.data_aditivo" type="date"  :rules="dataAditivoRules()">
              <template v-slot:label>
                <span>Datra Início do Aditivo <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete outlined v-model="procedure.procedimento" :items="formattedProcedures"
              item-value="id" item-text="displayText" required clearable :rules="requiredField"
              @input="logProcedureInfo(procedure, i)" :loading="loadingprocedimentos">
              <template v-slot:label>
                <span>Procedimento <span style="color: red;">*</span></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete outlined v-model="procedure.frequencia" :items="frequencias" clearable
              item-value="id" item-text="nome" required :rules="requiredField"
              @input="logFrequencyInfo($event, procedure)">
              <template v-slot:label>
                <span>Frequência <span style="color: red;">*</span></span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" v-if="procedure.especial">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined clearable step="0" type="number"
                  v-model="procedure.quantidade" v-bind="attrs" v-on="on">
                  <template v-slot:label>
                    <span>Quantidade Especial <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </template>
              <span>Campo Especial, quantidade de cobrança</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" v-if="procedure.especial">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined clearable step="0" type="date"
                  v-model="procedure.data_especial" v-bind="attrs" v-on="on">
                  <template v-slot:label>
                    <span>Data da Execução <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </template>
              <span>Campo Especial! Data da Execução do Procediemnto</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-text-field
              :rules="[e => e > 0 || `A quantidade precisa ser maior que zero`, e => e < 191 || `A quantidade não pode ser maior que 190`]"
              outlined clearable step="0" type="number"
              v-model="procedure.qtd">
              <template v-slot:label>
                <span>Quantidade de procedimentos <span style="color: red;">*</span></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-select outlined required v-model="procedure.cobranca" item-value="value" item-text="nome"
              :items="statusOptions">
              <template v-slot:label>
                <span>Cobrança <span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="1">
            <v-select outlined required label="Pagamento" v-model="procedure.pagamento" item-value="value"
              item-text="nome" :items="statusOptions">
              <template v-slot:label>
                <span>Pagamento<span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn text @click="handleAddProcedure" color="green">
        <v-icon left>mdi mdi-plus</v-icon>
        Adicionar procedimento
      </v-btn>
    </v-row>
    <v-row class="justify-end" dense>
      <ConfirmButton color="success" :loading="loading" :onConfirm="onBeforeSubmit" :block="false"> Gerar
        <v-icon right>
          mdi-cog
        </v-icon>
      </ConfirmButton>
    </v-row>
    <span style="color: red; font-size: 1.2em; ">* Em se tratando de <strong>Procedimento Especial</strong> a Frequência tem que ser <strong>
    1 X MÊS</strong> e a <strong>Quantidade Especial</strong> será a quantidade de dias do mês do orçamento.</span>
  </div>
</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';
import api from "../../../http";

const { currencyToNumber } = UtilsFunc;
export default {
  name: "GenerateProceduresForm",
  props: {
    treatmentFrequency: Object,
    procedimentos: Array,
    frequencias: Array,
    proceduresToCreate: Array,
    treatmentsToCreate: Number,
    canCreateTreatments: Boolean,
    statusOptions: Array,
    budgetId: Number,
    loading: Boolean,
    onSubmit: Function,
    orcamentoId: Number,
    dataInicio: [Date, String],
    dataFim: [Date, String],
  },
  data: () => UtilsFunc.withCRUDUtils({
    validating: false,
    localProceduresToCreate: [],
    loadingprocedimentos: false,
    aditivo:[],
    maxNrAditivo: null,
    maxDataAditivo: null,
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    nrAditivo: [
      v => !(this.localProceduresToCreate.some(p => p.aditivo === true)) || !!v || 'Nrº Aditivo é obrigatório quando Aditivo está como Sim'
    ], 
  }),
  methods: {
    async getProcedimento() {
      this.loadingprocedimento = true
      const operadoraId = this.operadoraId;
      try {
        const { data } = await api.get(`operadoras/operadora-procedimentos/?operadora=${operadoraId}`);
        this.proced = data
          .filter(proced => proced.ativo === true)
          // .sort((a, b) => a.procedimentos.nome.localeCompare(b.procedimentos.nome));
          // console.log('Procedimentos --> ', this.proced)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } finally {
        this.loadingprocedimento = false;
      }
    },
    async getAditivo() {
      this.loadingprocedimento = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = data.data_aditivo;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingTaxas = false;
      }
    },
    
    allFieldsValid() {
      return this.localProceduresToCreate.every(procedure => {
        const dataAditivo = new Date(procedure.data_aditivo);
        const dataInicio = new Date(this.dataInicio);
        const dataFim = new Date(this.dataFim);
        return procedure.procedimento &&
          procedure.frequencia &&
          procedure.aditivo !== null &&
          procedure.qtd !== null &&
          procedure.qtd !== '0' &&
          procedure.cobranca !== null &&
          procedure.pagamento !== null &&
          (!procedure.data_aditivo || (dataAditivo <= dataFim && dataAditivo >= dataInicio));
      });
    },
    async onBeforeSubmit() {
      this.validating = true;
      let isValid = true;

      this.localProceduresToCreate.forEach(procedure => {
        const dataAditivo = new Date(procedure.data_aditivo);
        const dataInicio = new Date(this.dataInicio);
        const dataFim = new Date(this.dataFim);

        if (procedure.aditivo === true && !procedure.nr_aditivo) {
          isValid = false;
          this.$toast.error('Por favor, preencha o Nrº do Aditivo para todos os procedimentos onde Aditivo está como Sim.');
        }
        if (procedure.data_aditivo && (dataAditivo < dataInicio || dataAditivo > dataFim)) {
          isValid = false;
          this.$toast.error('Data Início do Aditivo deve estar entre a Data de Início e a Data de Fim do Orçamento.');
        }
      });

      if (!isValid || !this.allFieldsValid()) {
        this.validating = false;
        return;
      }

      const fields = this.localProceduresToCreate.reduce((acc, { qtd, ...procedure }) => {
        for (let i = 0; i < qtd; i++) {
          acc.push({
            ...procedure, sessao: this.budgetId,
            valor_cobranca: Number(procedure.valor_cobranca),
            valor_cobranca_final: Number(procedure.valor_cobranca * procedure.quantidade).toFixed(2),
            
          });
        }
        return acc;
      }, []);

      await this.onSubmit(fields);
      this.validating = false;
      this.resetProcedures();
    },
    handleAddProcedure() {
      this.localProceduresToCreate.push({
        procedimento: null,
        frequencia: null,
        qtd:1,
        quantidade:1,
        cobranca: true,
        valor_cobranca: null,
        pagamento: true,
        aditivo: false,
        data_aditivo: null,
        nr_aditivo: null,
      });
    },
    logProcedureInfo(procedure) {
      const selectedProcedure = this.procedimentos.find(p => p.id === procedure.procedimento);
      if (selectedProcedure) {
        const preco = currencyToNumber(selectedProcedure.preco) || 0;
        procedure.valor_cobranca = selectedProcedure.preco;
        procedure.cobranca = preco !== 0;
        procedure.especial = selectedProcedure.procedimento.especial;

        // console.log('Procedimento especial:', procedure.especial);

        if (procedure.especial && procedure.aditivo) {
          procedure.frequencia = 11;
          procedure.quantidade = (this.differenceInDaysHoje + 1);
          procedure.data_especial = this.dataFim
          procedure.qtd = 1;
        } else if (procedure.especial) {
          procedure.frequencia = 11;
          procedure.quantidade = (this.differenceInDays + 1);
          procedure.data_especial = this.dataFim
          procedure.qtd = 1;
        } else {
          this.resetFrequencyAndQuantity(procedure);
        }
      } else {
        procedure.valor_cobranca = "0.00";
        procedure.cobranca = false;
        procedure.especial = false;
        procedure.quantidade = 1;
        procedure.data_especial = null,
        this.resetFrequencyAndQuantity(procedure);
      }
    },
    logFrequencyInfo(frequencyId, procedure) {
      const selectedFrequency = this.frequencias.find(f => f.id === frequencyId);
      if (selectedFrequency && !procedure.especial && procedure.aditivo) { 
        procedure.qtd = Math.max(1, Math.round((this.differenceInDaysHoje + 1) * selectedFrequency.fator));
      } else if (selectedFrequency && !procedure.especial ) { 
        procedure.qtd = Math.max(1, Math.round((this.differenceInDays + 1) * selectedFrequency.fator));
      } else if (procedure.especial) {
        procedure.qtd = 1;
        procedure.quantidade = (this.differenceInDays + 1);
      } else if (procedure.especial && procedure.aditivo) {
        procedure.qtd = 1;
        procedure.quantidade = (this.differenceInDays + 1);
      }
    },
    getDefaultProcedure() {
      return {
        frequencia: null,
        procedimento: null,
        pagamento: true,
        quantidade:1,
        qtd: 1, 
        cobranca: true,
        aditivo: false,
        data_aditivo: null,
        nr_aditivo: null,
      };
    },
    resetProcedures() {
      this.localProceduresToCreate = [this.getDefaultProcedure()];
    },
    nrAditivoRules(procedure) {
      if (procedure.aditivo === true) { 
        return [v => !!v || 'Nrº Aditivo é obrigatório'];
      }
      return []; 
    },
    dataAditivoRules() {
      return [
        v => !!v || 'Data Início do Aditivo é obrigatória',
        v => new Date(v) <= new Date(this.dataFim) || 'Data Início do Aditivo não pode ser maior que a Data de Fim do Orçamento.',
      ];
    },
    handleAditivoChange(index) {
      const procedure = this.localProceduresToCreate[index];
      const today = this.formatDate(new Date());
      const dateInicio = new Date(this.dataInicio);
      const dateFim = new Date(this.dataFim);

      let newDate = this.maxDataAditivo || today;

      // Garantir que a data esteja no intervalo entre dataInicio e dataFim
      const newDateObj = new Date(newDate);
      if (newDateObj < dateInicio || newDateObj > dateFim) {
        newDate = this.formatDate(dateInicio);
      }

      if (procedure.aditivo === true) {
        this.$set(this.localProceduresToCreate[index], 'nr_aditivo', this.maxNrAditivo || 1);
        this.$set(this.localProceduresToCreate[index], 'data_aditivo', newDate);
      } else {
        this.$set(this.localProceduresToCreate[index], 'nr_aditivo', null);
        this.$set(this.localProceduresToCreate[index], 'data_aditivo', null);
      }
      this.$set(this.localProceduresToCreate[index], 'frequencia', null);
      this.$set(this.localProceduresToCreate[index], 'procedimento', null);
      this.$set(this.localProceduresToCreate[index], 'qtd', 1);
    },
    resetFrequencyAndQuantity(procedure) {
      procedure.frequencia = null;
      procedure.quantidade = 1;
      procedure.qtd = 1;
    },
    
    formatDate(dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() + 1);
      const year = date.getFullYear();
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const day = (`0${date.getDate()}`).slice(-2);
      return `${year}-${month}-${day}`;
    },
  },
  components: { ConfirmButton },
  computed: {
    differenceInDays() {
      const date1 = new Date(this.dataInicio);
      const date2 = new Date(this.dataFim);
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    differenceInDaysHoje() {
      const date1 = this.localProceduresToCreate.some(p => p.aditivo) ? new Date(this.localProceduresToCreate.find(p => p.aditivo).data_aditivo) : new Date();
      const date2 = new Date(this.dataFim);
      const diffTime = Math.abs(date2 - date1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    formattedProcedures() {
      return this.procedimentos.map(procedure => {
        return {
          ...procedure,
        displayText: procedure.nomecomercial ? `${procedure.procedimento.nome} - "${procedure.nomecomercial}" - Cód.: ${procedure.codigo}` : (`${procedure.procedimento.nome} - Cód.: ${procedure.codigo}`)
        };
      });
    },
  },
  mounted() {
    this.getAditivo();
    this.localProceduresToCreate = [...this.proceduresToCreate];
    this.resetProcedures();
  },
  created() {
    this.resetProcedures();
  },
  watch: {
    maxNrAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.localProceduresToCreate.forEach(procedure => {
          if (procedure.aditivo) {
            this.$set(procedure, 'nr_aditivo', newVal);
            this.$set(procedure, 'data_aditivo', this.maxDataAditivo || this.formatDate(new Date()));
          }
        });
      }
    },
    maxDataAditivo(newVal) {
      if (newVal !== null && newVal !== undefined && newVal !== '') {
        this.localProceduresToCreate.forEach(procedure => {
          if (procedure.aditivo) {
            this.$set(procedure, 'data_aditivo', newVal);
          }
        });
      }
    },
    'procedure.procedimento': function(newProc, oldProc) {
      if (newProc !== oldProc) {
        const selectedProcedure = this.procedimentos.find(p => p.id === newProc);
        const procedure = this.localProceduresToCreate.find(p => p.procedimento === newProc); // Adicionado
        if (selectedProcedure && procedure) { // Adicionado verificação de procedure
          procedure.especial = selectedProcedure.procedimento.especial;
          this.resetFrequencyAndQuantity(procedure);
          if (procedure.especial) {
            procedure.frequencia = 11;
            procedure.qtd = 1;
            procedure.quantidade = 1;
          }
        } else if (procedure) { // Adicionado verificação de procedure
          this.resetFrequencyAndQuantity(procedure);
        }
      }
    },
    'procedure.frequencia': function(newFreq) {
      const selectedFrequency = this.frequencias.find(f => f.id === newFreq);
      const procedure = this.localProceduresToCreate.find(p => p.frequencia === newFreq); // Adicionado
      if (selectedFrequency && procedure && !procedure.especial && procedure.aditivo) { // Adicionado verificação de procedure
        procedure.qtd = Math.max(1, Math.round((this.differenceInDaysHoje + 1) * selectedFrequency.fator));
      } else if (selectedFrequency && procedure && !procedure.especial){
        procedure.qtd = Math.max(1, Math.round((this.differenceInDays + 1) * selectedFrequency.fator));
      } else if (procedure && procedure.especial) {
        procedure.qtd = 1;
        procedure.quantidade = 1;
      }
    },
    'localProceduresToCreate': {
      deep: true,
      handler(newProcedures) {
        newProcedures.forEach((procedure, index) => {
          this.$watch(() => procedure.nr_aditivo, (newVal, oldVal) => {
            if (newVal != null && oldVal != null) {
              if (newVal === this.maxNrAditivo) {
                this.$set(this.localProceduresToCreate[index], 'data_aditivo', this.maxDataAditivo);
              } else  {
                const today = new Date().toISOString().split('T')[0];
                this.$set(this.localProceduresToCreate[index], 'data_aditivo', today);
              }
            }
          });
        });
      }
    },
  }
}
</script>
